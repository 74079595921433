import { StoreAction } from "../../@core/constants/action";

const initialState = {
  productList: [],
  businessList: [],
  catalogueList: [],
  wabaBusinessDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_BUSINESS_LIST:
      return { ...state, businessList: action.payload };
    case StoreAction.SET_CATALOGUE_LIST:
      return { ...state, catalogueList: action.payload };
    case StoreAction.SET_PRODUCT_LIST:
      return { ...state, productList: action.payload };
    case StoreAction.SET_WABA_BUSINESS_DETAILS:
      return { ...state, wabaBusinessDetails: action.payload };
    default:
      return state;
  }
}
