import { StoreAction } from "../../@core/constants/action";

const initialState = { metaDetailsModal: 1 };

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_META_DETAILS_MODAL_TYPE:
      return { ...state, metaDetailsModal: action.payload };
    default:
      return state;
  }
}
