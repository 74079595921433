export const StoreAction = {
  QR_CODE: "QR_CODE",
  SET_CATEGORY: "SET_CATEGORY",
  SET_LANGUAGES: "SET_LANGUAGES",
  SET_DEVICE_ID: "SET_DEVICE_ID",
  SET_QR_LOADING: "SET_QR_LOADING",
  SET_WEB_SOCKET: "SET_WEB_SOCKET",
  SET_ACTIVE_CHAT: "SET_ACTIVE_CHAT",
  SET_CHAT_FILTER: "SET_CHAT_FILTER",
  SET_DEVICE_MODAL: "SET_DEVICE_MODAL",
  SET_CHAT_LOADING: "SET_CHAT_LOADING",
  REQUESTED_DEVICE: "REQUESTED_DEVICE",
  SET_PRODUCT_LIST: "SET_PRODUCT_LIST",
  SET_BUSINESS_LIST: "SET_BUSINESS_LIST",
  GET_ALL_CHAT_LIST: "GET_ALL_CHAT_LIST",
  SET_TEMPLATE_LIST: "SET_TEMPLATE_LIST",
  SET_CHAT_SIDE_BAR: "SET_CHAT_SIDE_BAR",
  SET_SELECTED_CHAT: "SET_SELECTED_CHAT",
  SET_SCROLL_ADJUST: "SET_SCROLL_ADJUST",
  SET_CUSTOMER_LIST: "SET_CUSTOMER_LIST",
  GET_TEMP_CATEGORY: "GET_TEMP_CATEGORY",
  SET_GET_CHAT_LIST: "SET_GET_CHAT_LIST",
  SET_IS_SUBMITTING: "SET_IS_SUBMITTING",
  GET_ALL_STAFF_LIST: "GET_ALL_STAFF_LIST",
  SET_CATALOGUE_LIST: "SET_CATALOGUE_LIST",
  SET_SCROLL_LOADING: "SET_SCROLL_LOADING",
  SET_STOP_SCROLLING: "SET_STOP_SCROLLING",
  SET_IS_SEND_MESSAGE: "SET_IS_SEND_MESSAGE",
  SET_DEVICE_MODAL_TYPE: "SET_DEVICE_MODAL_TYPE",
  SET_CHAT_BOT_FLOW_LIST: "SET_CHAT_BOT_FLOW_LIST",
  SET_USER_DETAILS_SIDEBAR: "SET_USER_DETAILS_SIDEBAR",
  SET_WABA_BUSINESS_DETAILS :"SET_WABA_BUSINESS_DETAILS",
  GET_ALL_CUSTOMER_LABEL_LIST: "GET_ALL_CUSTOMER_LABEL_LIST",
  SET_META_DETAILS_MODAL_TYPE: "SET_META_DETAILS_MODAL_TYPE",
  SET_QUICK_REPLY_MESSAGE_LIST: "SET_QUICK_REPLY_MESSAGE_LIST",
};
